import {PageBase} from "../../components/pageBase/pageBase";
import {makeStyles} from "tss-react/mui";
import {Route, Routes, useParams} from "react-router-dom";
import {GroupPageContent} from "./GroupPageContent";
import {GroupCourse} from "./GroupCourse";
import {OwnedGroupDataProvider} from "@plumeuk/shapeshift-common/providers";
import {useMq} from "@plumeuk/shapeshift-common/hooks";
import {MobileSideMenu} from "../../components/MobileSideMenu";
import {LinearProgress} from "@mui/material";

const useStyles = makeStyles()((_theme) => ({
	sideMenu: {
		color: "black"
	}
}));

export const GroupPage: React.FC = () => {
	const {groupSlug} = useParams();
	const {classes} = useStyles();
	const mq = useMq();

	return (
		<PageBase disableSideBar>
			<OwnedGroupDataProvider slug={groupSlug}>
				{({group, getGroup, apiResult}) => apiResult.isLoading ? <LinearProgress /> :
					<Routes>
						<Route index element={<GroupPageContent group={group} refreshGroup={() => getGroup(groupSlug, undefined, {cache: false})}/>}/>
						<Route path=":courseSlug" element={<GroupCourse group={group} />} />
					</Routes>
				}
			</OwnedGroupDataProvider>
		</PageBase>
	);
}