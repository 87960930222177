import {Box, Button, LinearProgress, Typography} from "@mui/material";
import {FC, useContext, useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {AuthContext, useApi} from "@plumeuk/shapeshift-identity";
import {DynamicContainer, LoginForm, LoginNav} from "@plumeuk/shapeshift-common/v2";
import {makeStyles} from "tss-react/mui";
import {useMq} from "@plumeuk/shapeshift-common/hooks";
import {palette} from "../../constants";
import {getTitle} from "../../utils/title";

const useStyles = makeStyles()((theme) => ({
	loginPage:{
		backgroundColor: theme.palette.background.default,
		minHeight: "100%",
		width: "100%",
		boxSizing: "border-box",
		display: "flex",
		alignItems: "center",
		"& [ class*='MuiInput']:before": {
			border: "none!important"
		},
		flexDirection: "column",
		[theme.breakpoints.down("sm")]: {
			backgroundColor: theme.palette.background.paper,
			width: "100%",
			padding: "5px"
		},
		"& input": {
			border: "none"
		}
	},
	imgContainer: {
		textAlign: "center",
		maxHeight: "100px",
		width: "50%",
		margin: "auto",
		display: "inline-flex",
		justifyContent: "center",
		[theme.breakpoints.down("sm")]: {
			marginBottom: "30px"
		},
		"& img": {
			[theme.breakpoints.down("sm")]: {
				width: "80px"
			}
		}
	},
	loginForm: {
		marginBottom: theme.spacing(2),
		"& .MuiInputBase-root": {
			borderRadius: "10px"
		},
		"& .MuiFormControl-root": {
			boxSizing: "border-box",
			paddingLeft: 0,
			paddingRight: 0
		},
		"& a": {
			cursor: "pointer"
		},
		"& [class*='resetPassword']": {
			textDecoration: "none",
			fontWeight: theme.typography.fontWeightBold
		}
	},
	copy: {
		color: palette.darkGrey,
		textAlign: "center",
		maxWidth: "560px",
		paddingBottom: "20px",
		alignSelf: "center"
	},
	accountPromptContainer: {
		"& h2": {
			marginBottom: "40px"
		},
		maxWidth: "560px",
		textAlign: "center",
		"& button": {
			width: "200px"
		}
	},
	navBar: {
		padding: 0,
		boxSizing: "border-box",
		"& > *": {
			boxSizing: "border-box"
		},
		"& .MuiToolbar-root": {
			boxSizing: "border-box",
			gridTemplateRows: "64px",
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			"& > *": {
				boxSizing: "border-box",
				maxHeight: "100%",
				height: "100%"
			},
			"& *:has(img)": {
				height: "100%"
			},
			"& img": {
				width: "auto"
			}
		}
	},
	subtitle: {
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: theme.spacing(3)
	}
}));

export const LoginPage: FC = () => {
	const mq = useMq();
	const logo = mq.desktop ? "/logo.svg" : "/emblem.svg"
	const {classes, cx} = useStyles();
	const {authenticated} = useContext(AuthContext);
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const [userExists, userExistsApi] = useApi<boolean | "Open Invite", string>();
	const [showAccountPrompt, setShowAccountPrompt] = useState<boolean>(false);

	const title = getTitle();

	useEffect(() => {
		if(!authenticated)
			return;
		const redirectPath = searchParams.get("redirectPath");
		const redirectSearch = searchParams.get("redirectSearch");

		navigate(redirectPath
			? {
				pathname: redirectPath,
				search: redirectSearch ?? ""
			} : "/courses");

	}, [authenticated]);

	const handleOnRegisterAction = (): void => {
		navigate(`/register/${location.search}`);
	}

	useEffect(() => {
		if(searchParams.get("redirectSearch")?.includes("token")){
			const token = searchParams
				.get("redirectSearch")
				?.split("&")
				?.find(e => e.includes("token"))
				?.replace("token=", "").replace("?", "");

			userExistsApi("/api/user/exists/" + token)
		}
	}, [])

	useEffect(() => {
		if(userExists.data === false){
			navigate(`/register/${location.search}`);
		}
		if(userExists.data === "Open Invite"){
			setShowAccountPrompt(true);
		}
	}, [userExists.data])

	return (
		<Box className={classes.loginPage}>
			<LoginNav
				className={classes.navBar}
				logo={logo}
				onRegisterAction={handleOnRegisterAction}
			/>
			<DynamicContainer noGutter={true} size="constrained">
				{userExists.isLoading
					? <LinearProgress />
					: showAccountPrompt
						? <Box className={classes.accountPromptContainer}>
							<Typography variant="h1" >{title}</Typography>
							<Typography className={classes.copy}>You&apos;ve been invited to a group! <br/>You&apos;ll need to login to claim access, do you already have an account?.</Typography>
							<Box style={{display: "flex", gap: "20px", width: "420px", margin: "50px auto 50px auto"}}>
								<Button onClick={() => setShowAccountPrompt(false)}>Yes</Button>
								<Button onClick={() => handleOnRegisterAction()}>No</Button>
							</Box>

						</Box>
						: <LoginForm
							className={classes.loginForm}
							title={`Login to ${title}`}
							onLogin={() => {}}
							onForgotPasswordAction={() => navigate("forgotten-password")}
							componentOverrides={{title: <><Typography variant="h1">{`Login to ${title}`}</Typography><Typography className={cx(classes.copy, classes.subtitle)}>If your account has been used to purchase anything from our shop, please use the same credentials {showAccountPrompt ? "" : "to login here"}.</Typography></>}}
						/>}
				{userExists.data === true && <Typography className={classes.copy}>You&apos;ve been invited to a group! Please sign in to claim.</Typography>}
			</DynamicContainer>
		</Box>
	);
}