import {CourseSideMenuContent as CommonCourseSideMenuContent} from "@plumeuk/shapeshift-common/course";
// import {CourseSideMenuContent as CommonCourseSideMenuContent} from "@plumeuk/shapeshift-common/v2";
import {moduleSlim, moduleTypeSlim} from "@plumeuk/shapeshift-types/ICourse";
import {FC, useCallback, useContext, useEffect, useState} from "react";
import {Link, useMatch, useNavigate, useParams} from "react-router-dom";
import {CurriculumContext} from "../../contexts/curriculumContext";
import {makeStyles} from "tss-react/mui";
import {palette} from "../../constants";
import {INotificationSeverity, NotificationContext} from "@plumeuk/shapeshift-identity";
import {ICourseCurriculumModule} from "@plumeuk/shapeshift-types";
import {Box} from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import {useMq} from "@plumeuk/shapeshift-common/hooks";

const useStyles = makeStyles<boolean>()((theme, completeRoute) => ({
	sideMenuContent:{
		"& [ class*='courseTitle'], & [ class*='title'], & [ class*='percentLabel']": {
			color: "black"
		},
		"& [ class*='MuiSvgIcon-root']": {
			color: palette.darkerGrey + " !important",
			opacity: "1 !important"
		},
		"& .module-complete svg": {
			opacity: "1 !important"
		},
		"& .module-complete.module-complete-children svg": {
			background: palette.green + " !important",
			color: "white !important",
			borderRadius: "100%"
		},
		"& [ class*='expandIcon']": {
			color: palette.darkBlue
		},
		"& [ class*='complete-btn'] [ class*='MuiSvgIcon-root']": {
			color: "red !important"
		},
		"& .isActive [ class*='contentContainer']": {
			borderColor: palette.purple
		},
		"& [ class*='contentContainer'] > div:first-child": {
			justifyContent: "start",
			paddingTop: "5px"
		}
	},
	certificateLink: {
		border: "1px solid",
		borderColor: completeRoute ? theme.palette.primary.main : "transparent",
		borderRadius: "10px",
		margin: "10px 35px 10px 25px",
		padding: "5px",
		color: theme.palette.success.main,
		textDecoration: "none",
		"& svg": {
			position: "relative",
			top: "3px",
			fontSize: "20px",
			marginRight: "10px"
		}
	}
}));

interface ICourseSideMenuContentProps {
	setSideMenuOpen?: (open: boolean) => void;
}

export const CourseSideMenuContent: FC<ICourseSideMenuContentProps> = ({setSideMenuOpen}) => {
	const mq = useMq();
	const completeRoute = useMatch("/course/:courseSlug/complete");
	const {classes} = useStyles(!!completeRoute);
	const pageIsModule = useMatch("/course/:courseSlug/:moduleType/:moduleSlug/*");
	const moduleSlug = pageIsModule?.params.moduleSlug;
	const moduleType = pageIsModule?.params.moduleType;
	const {courseSlug} = useParams();
	const navigate = useNavigate();
	const {curriculumState, curriculumDispatch} = useContext(CurriculumContext);
	const [activeModule, setActiveModule] = useState<moduleSlim>();
	const {notify} = useContext(NotificationContext);

	useEffect(() => {
		if(moduleSlug && moduleType)
			setActiveModule({slug: moduleSlug, type: moduleType as moduleTypeSlim});
	}, [moduleSlug, moduleType]);

	const handleActiveModuleCallback = useCallback((module: ICourseCurriculumModule): void => {
		navigate(`/course/${courseSlug}/${module.type}/${module.slug}`);
		if (mq.mobile) {
			setSideMenuOpen?.(false);
		}
	}, [navigate]);

	const handleSetModuleComplete = useCallback((module: moduleSlim, status: boolean): void => {
		curriculumDispatch({type: "setModuleComplete", module, status})
	}, [curriculumDispatch]);

	const handleModuleForbidden = useCallback((moduleRequest: ICourseCurriculumModule, nextModule?: ICourseCurriculumModule): void => {
		notify(`Modules must be completed in sequence. Next up is ${nextModule?.title}`, "Hey now, slow down!",INotificationSeverity.warning, 5000);
	}, [curriculumDispatch]);

	return (
		<>
			<CommonCourseSideMenuContent
				className={classes.sideMenuContent}
				style={{color: "black"}}
				data={curriculumState}
				onModuleForbidden={handleModuleForbidden}
				courseSlug={courseSlug}
				onActiveChange={handleActiveModuleCallback}
				levelIndentSize={28}
				onModuleComplete={handleSetModuleComplete}
				activeModule={pageIsModule ? activeModule : undefined}
			/>
			{curriculumState?.percentCompleted === 100 && <Link
				className={classes.certificateLink}
				to={"complete"}
			>
				<Box><FileCopyIcon /> Certificate</Box>
			</Link>}
		</>
	);
}