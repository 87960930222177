import {Alert, Box, Button, LinearProgress, Typography} from "@mui/material";
import {CenterTile} from "@plumeuk/shapeshift-common/pageBase";
import {Modal} from "@plumeuk/shapeshift-common/modal";
import {FC, useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {makeStyles} from "tss-react/mui";
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";
import {palette} from "../../constants";
import EditUserDetailsForm from "../../components/editUserDetailsForm/editUserDetailsForm";
import ConfettiExplosion from "react-confetti-explosion";
import {AuthContext, INotificationSeverity, NotificationContext, useApi} from "@plumeuk/shapeshift-identity";
import {useFileBaseUrl, useMq} from "@plumeuk/shapeshift-common/hooks";
import {ICourseCertificateAddress, isCourseCertificateAddressValid} from "../../types/ICourseCertificateAddress";
import {Refresh} from "@mui/icons-material";

const useStyles = makeStyles<ICourseCertificateAddress | undefined>()((theme, details) => ({
	centeredContainer: {
		paddingBottom: "60px",
		[theme.breakpoints.down("sm")]: {
			paddingTop: "60px",
			"& [class*='centerTile']": {
				paddingLeft: 0,
				paddingRight: 0
			}
		},
		textAlign: "center",
		"& > div": {
			maxHeight: "100%",
			paddingBottom: "100px"
		},
		"& [ class*='buttonContainer']": {
			display: "inline-block",
			margin: "10px"
		}
	},
	title: {
		marginBottom: "40px",
		display: "block"
	},
	tagLine: {
		color: "#2A2A2F",
		fontWeight: 400
	},
	confirmBtn: {
		display: "inline-block",
		marginBottom: "50px"
	},
	userDetailFormContainer: {
		minHeight: "270px",
		backgroundColor: palette.pastelBlue,
		border: palette.grey + " 1px solid",
		padding: "25px",
		margin: "25px",
		borderRadius: "15px",
		"& table": {
			textAlign: "left"
		},
		"& td": {
			padding: "5px 20px"
		},
		"& td: nth-child(2)": {
			fontWeight: 600
		},
		[theme.breakpoints.down("sm")]: {
			margin: "25px 0"
		}
	},
	editForm: {
		maxHeight: "600px",
		overflowY: "auto"
	},
	expandLabel: {
		cursor: "pointer"
	},
	confetti: {
		border: "1px solid red",
		textAlign: "center",
		width: 0,
		margin: "auto",
		zIndex: "-1"
	},
	printedNameLabel: {
		color: details?.printedName ? "inherit" : theme.palette.error.main
	},
	addressLabel: {
		color: (details?.addressType === "Group leader" || details?.address) ? "inherit" : theme.palette.error.main
	},
	refreshBtn: {
		cursor: "pointer",
		transition: "opacity .3s ease-in-out",
		opacity: .5,
		borderRadius: "5px",
		"& svg": {
			position: "relative",
			top: "6px",
			left: "-5px",
			opacity: .5
		},
		"&:hover": {
			opacity: .8
		}
	}
}));

export const CourseCompleteUserDetailConfirmationPage: FC = () => {
	const {user} = useContext(AuthContext);
	const mq = useMq();
	const {courseSlug} = useParams();
	const navigate = useNavigate();
	const [isConfettiRunning, setIsConfettiRunning] = useState(true);
	const [editUserModalOpen, setEditUserModalOpen] = useState(false);
	const {notify} = useContext(NotificationContext);
	const {getUrl} = useFileBaseUrl();
	const [{data: apiData, isLoading, errorData}, getAddress] = useApi<ICourseCertificateAddress>();
	const {classes} = useStyles(apiData);
	const [confirmResponse, confirmDetailsApi] = useApi();

	const confirmButton = <Button onClick={() => handleConfirmAction()} className={classes.confirmBtn}>Confirm details</Button>;
	const editButton = <Button onClick={() => setEditUserModalOpen(true)} className={classes.confirmBtn}>Edit details</Button>;
	const h = window.innerHeight;

	useEffect(() => {
		if(courseSlug)
			getAddress({url: "/api/user/getCertificateAdress/" + courseSlug, cache: false})
	}, [courseSlug, user])


	const handleConfirmAction = (): void => {
		if(!apiData){
			notify("Please contact your system administrator", "Failed to confirm", INotificationSeverity.error, 10000)
			return;
		}
		if(!isCourseCertificateAddressValid(apiData)){
			notify( "Please update your details before proceeding", "Missing user details!", INotificationSeverity.warning, 8000);
			return;
		}

		confirmDetailsApi({
			method: "PUT",
			url: "/api/user/confirmCertificateAddress/" + courseSlug
		})
	}

	useEffect(() => {
		if(confirmResponse.isError)
			notify( "Please check your details", "Something went wrong!", INotificationSeverity.warning, 8000);
		else if(confirmResponse.data)
			navigate("../complete", {replace: true})
	}, [confirmResponse])

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsConfettiRunning(false);
		}, 4000);

		return () => clearTimeout(timer);
	}, []);

	useEffect(() => {
		if(apiData?.confirmed || apiData?.deliveredCertificate == false)
			navigate("../complete", {replace: true})
	}, [apiData]);

	return (
		<>
			<CenterTile
				className={classes.centeredContainer}
				title="Congratulations"
				icon={<WorkspacePremiumOutlinedIcon />}
				buttons={[editButton, confirmButton]}
			>
				<Typography variant="h6" className={classes.tagLine}>
					You finished the course and will be awarded with a certificate.
					<br />
					But first, please confirm the certificate delivery details.
				</Typography>
				<Box className={classes.userDetailFormContainer}>
					{apiData && !mq.mobile && <table>
						<tbody>
							<tr>
								<td className={classes.printedNameLabel}>Printed Name:</td>
								<td>{apiData.printedName}</td>
							</tr>
							<tr>
								<td className={classes.addressLabel}>Address:</td>
								<td>{apiData?.addressType === "Group leader" ? "The Certificate will be delivered to your group leader" : apiData?.address}</td>
							</tr>
							<tr><td><Box onClick={() => getAddress()} className={classes.refreshBtn}><Refresh /> Refresh</Box></td></tr>
						</tbody>
					</table>}
					{apiData && mq.mobile && <Box>
						<Box>
							<Typography><strong>Printed Name:</strong></Typography>
							<Typography>{apiData.printedName}</Typography>
							<br />

							<Typography><strong>Address:</strong></Typography>
							<Typography>{(apiData?.addressType === "Group leader" ? "The Certificate will be delivered to your group leader" : (apiData?.address?.length === 0 ? "Not found" : apiData?.address)) ?? "not found"}</Typography>
						</Box>
						<Box onClick={() => getAddress()} className={classes.refreshBtn}><Refresh /> Refresh</Box>

					</Box>}
					{isLoading &&<Box padding={3}> <LinearProgress /></Box>}
				</Box>
				{errorData && <Box padding={5}><Alert severity="error">Failed to retrieve account information</Alert></Box>}

				<Modal open={editUserModalOpen} onClose={() => setEditUserModalOpen(false)}>
					<>
						<Typography variant="h4" className={classes.title}>{user?.firstname} {user?.lastname}</Typography>
						<EditUserDetailsForm onClose={() => setEditUserModalOpen(false)} className={classes.editForm}/>
					</>
				</Modal>

			</CenterTile>
			{isConfettiRunning && <ConfettiExplosion className={classes.confetti} force={0.8} height={h} particleCount={250} duration={4000} />}
		</>
	);
}