import {CourseTable} from "@plumeuk/shapeshift-common/courses";
import {useNavigate} from "react-router-dom";
import {CenteredContainer} from "../../components/pageBase/centeredContainer";
import {PageBase} from "../../components/pageBase/pageBase";
import {makeStyles} from "tss-react/mui";
import {palette} from "../../constants";
import {MobileSideMenu} from "../../components/MobileSideMenu";
import {useMq} from "@plumeuk/shapeshift-common/hooks";

const useStyles = makeStyles()((theme) => ({
	pageBase: {
		"& [class*=topContainer] > h4": {
			fontWeight: 600
		}
	},
	sideMenu: {
		color: "black"
	},
	courseTable: {
		paddingLeft: theme.spacing(1.5),
		paddingRight: theme.spacing(1.5),
		"& [ class*='paper']": {
			background: "none"
		},
		"& [ class*='courseItem']": {
			borderRadius: "8px",
			background: palette.pastelBlue
		},
		"& [class*=contentContainer] > div": {
			border: "none"
		},
		"& [class*=infoContainer]": {
			paddingBottom: 0
		},
		"& [class*=infoContainer] h4": {
			marginBottom: "4px",
			fontWeight: 600
		},
		"& [class*=infoContainer] p": {
			display: "none"
		},
		"& [class*=loadingContainer]": {
			justifyContent: "start",
			paddingTop: "0"
		},
		"& [class*=buttonContainer]": {
			paddingTop: 0,
			paddingBottom: 0
		}
	}
}));


export const MyLearningPage: React.FC = () => {
	const navigate = useNavigate();
	const {classes} = useStyles();
	const mq = useMq();

	return (
		<PageBase className={classes.pageBase} disableSideBar>
			<CenteredContainer>
				<CourseTable
					className={classes.courseTable}
					sx={{paddingTop: "40px"}}
					onGoToCourseAction={courseId => {navigate("/course/" + courseId)}}
					onGoToCertificateAction={courseId => {navigate(`/course/${courseId}/complete`)}}
				/>
			</CenteredContainer>
		</PageBase>
	);
}