import {PageBase} from "../../../components/pageBase/pageBase";
import {useMq} from "@plumeuk/shapeshift-common/hooks";
import {MobileSideMenu} from "../../../components/MobileSideMenu";
import PortfolioAdminReviewDataProvider from "../../../providers/PortfolioAdminReviewDataProvider";
import {LinearProgress} from "@mui/material";
import {useParams} from "react-router-dom";
import {ModuleNotAvailable} from "@plumeuk/shapeshift-common/module";
import {PortfolioReviewPageContent} from "./PortfolioReviewPageContent";

export const PortfolioReviewPage: React.FC = () => {
	const mq = useMq();
	const {courseSlug, portfolioSlug, learnerId} = useParams();

	return (
		<PageBase disableSideBar>
			<PortfolioAdminReviewDataProvider courseSlug={courseSlug} portfolioSlug={portfolioSlug} learnerId={parseInt(learnerId ?? "0", 10)}>
				{({portfolioAdminEntry, apiResult, getPortfolioAdminReviewEntry}) => <>
					{apiResult.isLoading && <LinearProgress />}
					{apiResult.statusCode === 403 &&
							<ModuleNotAvailable>
								{apiResult.errorData?.error.message}. We&apos;ll email you when it&apos;s available
							</ModuleNotAvailable>
					}
					{(apiResult.statusCode === 200 && portfolioAdminEntry) && <PortfolioReviewPageContent onChange={() => getPortfolioAdminReviewEntry(undefined, undefined, undefined, {cache: false})} reviewEntry={portfolioAdminEntry}/>}
				</>}
			</PortfolioAdminReviewDataProvider>
		</PageBase>
	);
}