// eslint-disable-next-line
// @ts-nocheck
import {Library, LibraryItem, LibrarySearch} from "@plumeuk/shapeshift-common/library";
import {FC, useCallback, useState} from "react";
import {PageBase} from "../../components/pageBase/pageBase";
import {CourseLibraryBanner} from "./CourseLibraryBanner";
import {Grid, LinearProgress} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {LibraryItemContent} from "./CourseLibraryItemContent";
import {palette} from "../../constants";
import {ILibrarySearch, ILibraryItem} from "@plumeuk/shapeshift-types";
import {BrandSelect} from "../../components/BrandSelect";
import {LibraryCoursesDataProvider} from "@plumeuk/shapeshift-common/providers";
import {useMq} from "@plumeuk/shapeshift-common/hooks";
import {MobileSideMenu} from "../../components/MobileSideMenu";

const useStyles = makeStyles()((theme) => ({
	courseItem: {
		width: "100%",
		height: "100%",
		"& [class*='tagContainer'] button ": {
			background: palette.green,
			color: palette.black
		},
		"& [class*='tagContainer'] [class*='featuredTag']": {
			background: palette.pastelBlue,
			color: palette.purple
		}
	},
	pageBase: {
		background: palette.pastelBlue,
		"& [class*='courseLibrary'] > [class*='title'] ": {
			fontSize: "42px"
		}
	},
	search: {
		marginTop: "35px",
		"& .instructor-select":{
			display: "none"
		}
	},
	brandSelect: {
		border: palette.darkerGrey + " 1px solid",
		borderRadius: "5px"
	}
}));

export const CourseLibraryPage: FC = () => {
	const {classes} = useStyles();
	const mq = useMq();
	const [searchValue, setSearchValue] = useState<ILibrarySearch & {brand?: number}>({searchTerm: ""});

	const handleGoToCourse = useCallback((course: ILibraryItem): void => {
		const eLink = (course as any).ecommerceLink as string;
		if(eLink){
			window.location.href = eLink.startsWith("http") ? eLink : `//${eLink}`
		}
		//Removed standard redirect to product page
		// else
		// 	navigate(`/library/${course.slug}`)
	}, []);

	return (
		<PageBase contentWidth="1300px" disableSideBar className={classes.pageBase} sideBarContent={mq.mobile ? <MobileSideMenu /> : undefined}>
			<CourseLibraryBanner/>
			<br />
			<LibraryCoursesDataProvider search={searchValue}>
				{({courses, apiResult}) => apiResult.isLoading ? <LinearProgress /> :
					<Library
						data={courses?.data?.map(e => ({...e, __type: "plugin::strapi-plugin-shapeshift-cms.course"}))}
						sx={{paddingBottom: "70px"}}
						componentOverrides={{
							librarySearch:
								<LibrarySearch
									className={classes.search}
									value={searchValue}
									onChange={setSearchValue}
									componentOverrides={{
										footer:
											<BrandSelect
												className={classes.brandSelect}
												value={searchValue?.brand}
												onChange={e => setSearchValue(prev => ({...prev, brand: (e.target.value as number )}))}
											/>
									}}
								/>
						}}
					>
						{(items) => <>
							{items?.map((item, i) => (
								<Grid item xs={12} sm={6} md={4} lg={3} key={"Course-item-" + i}>
									<LibraryItem
										item={item}
										className={classes.courseItem}
										onGoToItemAction={() => handleGoToCourse(item)}
										componentOverrides={{content: <LibraryItemContent course={item}/>}}
									/>
								</Grid>
							))}
						</>}


					</Library>}
			</LibraryCoursesDataProvider>
		</PageBase>
	);
}