import {Box, Button, LinearProgress, Typography} from "@mui/material";
import {FC, useContext, useState} from "react";
import {makeStyles} from "tss-react/mui";
import {AuthContext, IForgetPasswordRequest, hitApi} from "@plumeuk/shapeshift-identity";
import {palette} from "../../constants";
import {getURl} from "../..";
import {Link} from "react-router-dom";
import {DynamicContainer, ForgottenPasswordForm, LoginNav} from "@plumeuk/shapeshift-common/v2";
import {useNavigate} from "react-router-dom";

const logo = "/logo.svg"

const useStyles = makeStyles()((theme) => ({
	forgottenPasswordPage:{
		backgroundColor: theme.palette.background.default,
		minHeight: "100%",
		width: "100%",
		boxSizing: "border-box",
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		[theme.breakpoints.down("sm")]: {
			backgroundColor: theme.palette.background.paper
		}
	},
	container: {
		maxWidth: "400px",
		marginTop: "15px",
		margin: "auto",
		display: "flex",
		flexDirection: "column",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column-reverse"
		}
	},
	imgContainer: {
		margin: "auto",
		[theme.breakpoints.down("sm")]: {
			margin: "30px 0",
			width: "100%"
		},
		"& img": {
			margin: "30px",
			height: "30px"
		}
	},
	backButton: {
		margin: "30px"
	},
	forgottenPasswordForm: {
		marginTop: "15px",
		"& .MuiInputBase-root": {
			borderRadius: "10px"
		},
		"& .MuiFormControl-root": {
			boxSizing: "border-box",
			paddingLeft: 0,
			paddingRight: 0
		}
	},
	copy: {
		color: palette.darkGrey,
		textAlign: "center"
	},
	navBar: {
		padding: 0,
		boxSizing: "border-box",
		"& > *": {
			boxSizing: "border-box"
		},
		"& .MuiToolbar-root": {
			boxSizing: "border-box",
			gridTemplateRows: "64px",
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			"& > *": {
				boxSizing: "border-box",
				maxHeight: "100%",
				height: "100%"
			},
			"& *:has(img)": {
				height: "100%"
			},
			"& img": {
				width: "auto"
			}
		}
	}
}));

export const ForgottenPasswordPage: FC = () => {
	const {classes} = useStyles();
	const [email, setEmail] = useState<string>("");
	const [isWpUser, setIsWpUser] = useState<boolean>();
	const [isLoading, setIsLoading] = useState(false);
	const {forgottenPassword} = useContext(AuthContext);
	const navigate = useNavigate();

	const handleSubmitEmail = (e: IForgetPasswordRequest): void => {
		setEmail(e.email)
		setIsLoading(true)
		hitApi<boolean, string>("/api/user/isWpUser?email=" + encodeURIComponent(e.email), {baseApiUrl: getURl()})
			.then(response => {
				if(response === true){
					window.location.href = "https://ukfiredoortraining.com/my-account/edit-account";
				}
				else {
					setIsWpUser(false);
					forgottenPassword(e.email);
					setIsLoading(false);
				}
			});
	}

	return (
		<Box className={classes.forgottenPasswordPage}>
			<LoginNav
				className={classes.navBar}
				logo={logo}
				onBackAction={() => navigate("/")}
			/>
			<DynamicContainer noGutter={true} size="constrained">
				{!isLoading && isWpUser === undefined && <ForgottenPasswordForm
					className={classes.forgottenPasswordForm} title="Password recovery"
				/>}

				{email && isWpUser === false && <>
					<Typography variant="body1">An email has been sent to {email} with a link to reset your password.</Typography>
					<Button className={classes.backButton} component={Link} to="/">Go back to login</Button>
				</>}
				{isLoading && <LinearProgress />}
				<Typography className={classes.copy}><strong>Please note:</strong> If your account has been used to purchase anything from our shop, your credentials are managed from there. You will be redirected if applicable.</Typography>
			</DynamicContainer>
		</Box>
	);
}

