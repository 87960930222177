import {Box, BoxProps} from "@mui/material";
import React, {FC, MouseEventHandler, ReactNode, cloneElement, isValidElement, useContext, useEffect, useRef, useState} from "react";
import {NavBar, SideMenu} from "@plumeuk/shapeshift-common/v2";
import {makeStyles} from "tss-react/mui";
import {useMq} from "@plumeuk/shapeshift-common/hooks";
import {useLocation, useMatch, useNavigate} from "react-router-dom";
import {palette} from "../../constants";
import {AuthContext} from "@plumeuk/shapeshift-identity";
import EditUserDetailsForm from "../editUserDetailsForm/editUserDetailsForm";
import {PageBaseContext} from "../../contexts/pageBaseContext";

interface ICustomProps {
	children: React.ReactNode,
	contentWidth?: string,
	sideBarContent?: ReactNode,
	disableSideBar?: boolean,
	sideMenuWidthOverride?: string
}

interface IStyleProps {
	sideMenuOpen: boolean,
	sideMenuWidthOverride?: string
}

const useStyles = makeStyles<IStyleProps>()((theme, {sideMenuOpen, sideMenuWidthOverride}) => ({
	pageBase: {
		height: "100%",
		"& [class*='bellIconContainer']": {
			display: "none"
		}
	},
	background: {
		backgroundColor: theme.palette.background.default,
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: "-1",
		position: "fixed"
	},
	navBarSpacer: {
		width: "100%",
		height: theme.constants.toolbarHeight
	},
	contentContainer: {
		position: "absolute",
		overflowX: "hidden",
		overflowY: "scroll",
		boxSizing: "border-box",
		top: 0,
		left: 0,
		height: "100%",
		width: "100%"
	},
	sideMenu: {
		width: sideMenuWidthOverride || theme.constants.sideMenuWidth,
		backgroundColor: theme.palette.background.default,
		position: "fixed",
		paddingBottom: theme.spacing(8),
		"& .MuiDrawer-paper": {
			paddingTop: 0
		},
		"& ::-webkit-scrollbar": {
			display: "none"
		},
		"& [class*='courseSideBarExpander']": {
			backgroundColor: palette.pastelBlue,
			"& svg": {
				"& path": {
					fill: theme.palette.primary.main
				}
			}
		}
	},
	pageBody: {
		position: "relative",
		height: "100%",
		width: sideMenuOpen ? `calc(100% - ${sideMenuWidthOverride || theme.constants.sideMenuWidth})` : "100%",
		left: sideMenuOpen ? (sideMenuWidthOverride || theme.constants.sideMenuWidth) : "0px",
		[theme.breakpoints.down("md")]: {
			left: 0,
			width: "100%"
		},
		transition: theme.transitionSpeeds.medium
	},
	appBar: {
		position: "sticky",
		transition: theme.transitionSpeeds.medium,
		background: theme.constants.navBackground,
		"& [class*='navBarTabs-tabs']": {
			justifyContent: "center"
		},
		"& [class*='mobileNavBarTabs']":{
			background: `${theme.palette.background.default}`
		},
		"& [class*='navBarTab'].Mui-selected": {
			color: theme.palette.text.primary,
			borderBottom: `4px solid ${theme.palette.primary.main}`
		},
		"& [class*='mobileNavBarTab'].Mui-selected": {
			color: theme.palette.text.primary
		},
		"& .MuiTab-root":{
			color: theme.palette.text.primary,
			borderBottom: "4px solid transparent",
			borderTop: "4px solid transparent",
			fontSize: "1.2rem"
		},
		"& [class*='menuIconContainer']": {
			borderRight: `${theme.borderWidth.small} solid ${theme.palette.grey["100"]}`,
			background: `${theme.palette.background.default}`,
			color: theme.palette.text.primary,
			display: "flex",
			alignItems: "center"
		},
		"& [class*='navBarLogo']": {
			boxSizing: "border-box",
			padding: theme.spacing(2)
		},
		"& [class*='avatarContainer'] svg": {
			fill: theme.palette.text.primary
		},
		"& .MuiAvatar-root": {
			background: theme.palette.primary.main
		},
		"& [class*='profileIconContainer']:not([class*='profileAvatarContainer'])": {
			display: "none"
		}
	}
}));

type IProps = ICustomProps & Omit<BoxProps, "children">;
type pages = "Courses" | "Groups dashboard" | "Assessment dashboard"; //>"Library" 

type routeCallback = {
	[key in pages]: string;
};

const routesMap: routeCallback = {
	"Courses": "/courses",
	// "Library": "/library",
	"Groups dashboard": "/groups-dashboard",
	"Assessment dashboard": "/assessment-dashboard"
}

export const PageBase: FC<IProps> = ({sideBarContent, disableSideBar, sideMenuWidthOverride, contentWidth, children, className, ...props}) => {
	const mq = useMq();
	const [contextState, setContextState] = useContext(PageBaseContext)
	const {user} = useContext(AuthContext);
	const pageIsMyLearning = useMatch("/courses");
	const location = useLocation();
	const sideMenuOpen = contextState.sidebarOpen;
	const {classes, cx} = useStyles({sideMenuOpen, sideMenuWidthOverride});
	const navigate = useNavigate();
	const [tabs, setTabs] = useState<pages[]>([]);
	const [firstRender, setFirstRender] = useState(true);
	const logo = mq.desktop ? "/logo.svg" : "/emblem.svg"
	const contentContainer = useRef<HTMLElement>(null);
	const [mobileNavOpen, setMobileNavOpen] = useState(false);

	const disableBurger = mq.desktop;

	const setSideMenuOpen = (e: boolean): void => setContextState(prev => ({...prev, sidebarOpen: e}))

	useEffect(() => setFirstRender(false), [])

	useEffect(() => {
		if(!firstRender){
			setContextState(prev => ({...prev, contentContainerRef: contentContainer}))
		}
	}, [firstRender])
	useEffect(() => setSideMenuOpen(!disableSideBar && mq.desktop), [disableSideBar, mq.desktop]);


	useEffect(() => {
		if(!user) return;

		const tabs: pages[] = ["Courses"]; //>, "Library"

		if(user.isOwner)
			tabs.push("Groups dashboard");

		if(user.isAuditor || user.isAssessor || user.isRegulatedAssessmentAdmin)
			tabs.push("Assessment dashboard");

		setTabs(tabs);
	}, [user])

	let pageSelected: pages | undefined = undefined;
	Object.keys(routesMap).forEach(e => {
		const page = e as pages;
		if(location.pathname.startsWith(routesMap[page]))
			pageSelected = page;
	})

	useEffect(() => {
		if(contextState.sidebarEnabled === false)
			setSideMenuOpen(false)
	}, [contextState]);

	const handlePageSelect = (page: pages): void => {
		setMobileNavOpen(false);
		navigate(routesMap[page]);
	}

	const handleContentClick: MouseEventHandler<HTMLDivElement> = (event): void => {
		const target = event.target as HTMLElement;
		if (target && "closest" in target && target.closest("[class*=\"courseSideMenuModule\"]")) {
			if(!mq.desktop && sideMenuOpen) setSideMenuOpen(false);
		}
	}

	const expandSidebar = (): void => {
		setContextState(prev => ({...prev, sidebarOpen: !prev.sidebarOpen}))
	};

	const isEnabledSidebar = !disableSideBar && contextState.sidebarEnabled

	return (
		<Box className={cx(classes.pageBase, className)} {...props}>
			<Box
				className={classes.contentContainer}
				onClick={handleContentClick}
				ref={contentContainer}
			>
				<NavBar<pages>
					onBurgerAction={() => setMobileNavOpen(prev => !prev)}
					className={classes.appBar}
					tabs={tabs}
					disableBurger={disableBurger}
					mobileNavOpen={mobileNavOpen}
					onPageSelect={handlePageSelect}
					pageSelected={pageSelected}
					logoPath={logo}
					editUserDetailsForm={EditUserDetailsForm}
				/>
				<SideMenu
					className={classes.sideMenu}
					open={sideMenuOpen}
					onExpandAction={isEnabledSidebar ? expandSidebar : undefined}
				>
					{isValidElement(sideBarContent) ? cloneElement(sideBarContent, {...sideBarContent?.props ?? {}, setSideMenuOpen}) : undefined}
				</SideMenu>
				<Box className={classes.pageBody}>
					{children}
				</Box>
			</Box>
			<Box className={classes.background} />
		</Box>
	);
};
