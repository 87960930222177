import {IUser as SSUser} from "@plumeuk/shapeshift-types";

export interface IEditUserRequest extends Omit<IUser, "blocked" | "provider" | "confirmed" | "blocked" | "createdAt" | "updatedAt"> {
	currentPassword: string,
	isWpUser?: boolean
}

export type IEditUserResponse = IEditUserRequest

export const TEditUserRequest: IEditUserRequest = {
	currentPassword: "",
	firstname: "",
	lastname: "",
	email: "",
	username: "",
	addressFirstLine: "",
	addressSecondLine: "",
	addressCity: "",
	addressCountry: "",
	addressPostcode: ""
}

export interface IUser extends SSUser {
	firstname: string;
	lastname: string;
	email: string;
	username: string;
	provider: string;
	confirmed: boolean;
	blocked: boolean;
	addressFirstLine?: string,
	addressSecondLine?: string,
	addressCity?: string,
	addressCountry?: string,
	addressPostcode?: string
}

