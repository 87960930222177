import {FC, useContext, useState, useEffect} from "react";
import {Box, BoxProps, Button, CircularProgress, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {AuthContext, INotificationSeverity, NotificationContext, useApi} from "@plumeuk/shapeshift-identity";
import {IEditUserRequest, IEditUserResponse, IUser, TEditUserRequest} from "../../types/IUser"
import {ValidationResult, useValidate} from "@plumeuk/use-validate";
import {editUserRequestValidationDoc} from "../../validationDocs/user/editUserDetailsRequestValidationDoc"
import {InputText} from "@plumeuk/shapeshift-common/inputText";
import {OutlineButton} from "@plumeuk/shapeshift-common/buttons";
import {IUser as SSUser} from "@plumeuk/shapeshift-types";
import {palette} from "../../constants";
import {useFileBaseUrl} from "@plumeuk/shapeshift-common/hooks";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

type IPropsCustom = {
	onClose?: () => void,
	className?: string
}

export type IProps = BoxProps & IPropsCustom;

const useStyles = makeStyles()((theme) => ({
	editAccount:{
	},
	title: {
		marginBottom: "40px",
		display: "block"
	},
	input: {
		marginTop: "20px"
	},
	buttonContainer: {
		gap: "10px",
		marginTop: "40px",
		display: "flex",
		justifyContent: "space-between",
		"& button": {
			width: "50%",
			height: "70px",
			padding: "20px 0px",
			[theme.breakpoints.down("sm")]: {
				width: "100%",
				marginBottom: "20px"
			}
		}
	},
	disclaimer: {
		color: palette.darkGrey,
		background: palette.pastelBlue,
		border: "1px solid "+ palette.grey,
		borderRadius: "5px",
		padding: "15px"
	}
}));

const convertContextUser = (user: SSUser): IEditUserRequest => {
	const userLocalTyped = user as unknown as IUser;

	return ({
		currentPassword: "",
		firstname: userLocalTyped.firstname,
		lastname: userLocalTyped.lastname,
		email: userLocalTyped.email,
		username: userLocalTyped.username,
		addressFirstLine: userLocalTyped.addressFirstLine,
		addressSecondLine: userLocalTyped.addressSecondLine,
		addressCity: userLocalTyped.addressCity,
		addressCountry: userLocalTyped.addressCountry,
		addressPostcode: userLocalTyped.addressPostcode
	});
}


export const EditUserDetailsForm: FC<IProps> = ({className, onClose, ...props}) => {
	const {classes, cx} = useStyles();
	const {notify} = useContext(NotificationContext);
	const [idFileUrl, setIdFileUrl] = useState<string>();
	const {user, setUser} = useContext(AuthContext);
	const [{data: isWpUser}, getIsWpUser] = useApi<boolean>(user ? "/api/user/isWpUser?email=" + encodeURIComponent(user.email) : undefined);
	const [formData, setFormData] = useState<IEditUserRequest>(user ? convertContextUser(user) : TEditUserRequest);
	const [updateUserResponse, updateUser] = useApi<IEditUserResponse>();
	const [validationResult, runValidate, hasErrors] = useValidate<IEditUserRequest>(editUserRequestValidationDoc, formData);
	const [postRequestValidation, setPostRequestValidation] = useState<ValidationResult<IEditUserRequest>>({});

	useEffect(() => {
		if(user)
			getIsWpUser("/api/user/isWpUser?email=" + encodeURIComponent(user.email))
	}, [user])

	useEffect(() => {
		if(isWpUser !== undefined)
			setFormData(prev => ({...prev, isWpUser}))
	}, [isWpUser])

	useEffect(() => {
		if(!user) return;
		setFormData(convertContextUser(user));
	}, [user])

	const handleSubmit = async (): Promise<void> => {
		setPostRequestValidation({});

		if(!runValidate())
			return;

		updateUser({
			method: "PUT",
			url: "/api/user",
			data: formData,
			config: {
			}
		})
	}

	useEffect(() => {
		if(updateUserResponse.data && !updateUserResponse.isError && !updateUserResponse.isLoading){
			notify("", "User details updated!", INotificationSeverity.success, 8000);
			setUser(prev => {
				if(!prev || !updateUserResponse.data)
					return;

				return {
					...prev,
					...updateUserResponse.data
				}
			})
			onClose?.();
		}
		else if(updateUserResponse.isError && !updateUserResponse.isLoading){
			notify("Please ensure the password is correct", "Something went wrong", INotificationSeverity.error, 8000);
			setPostRequestValidation({currentPassword: "Failed to update, please confirm the password is correct"})
		}
	}, [updateUserResponse]);

	const input = (label: string, prop: keyof IEditUserRequest, type = "text"): JSX.Element => (
		<InputText
			className={classes.input}
			label={label}
			type={type}
			autoComplete="off"
			value={formData[prop]}
			error={validationResult[prop] ?? postRequestValidation[prop]}
			onChange={e => setFormData(prev => ({...prev, [prop]: e.target.value}))}
			onValidate={() => runValidate(prop)}
		/>
	)


	return (
		<Box className={cx(classes.editAccount, className)} {...props}>
			<form autoComplete="off">
				{isWpUser && <Box className={classes.disclaimer}>
					<Box style={{display: "flex", gap: "10px", marginBottom: "10px"}}>
						<ErrorOutlineIcon />
						<Typography><strong>Please note:</strong></Typography>
					</Box>

					<Typography>As you have purchased from our shop, your email and address information must be managed from there. Please ensure your Billing address is set to your desired certificate delivery address.</Typography>
					<a href="https://ukfiredoortraining.com/my-account/edit-account"><Button style={{margin: "20px 0"}}>Go to shop</Button></a>
					<Typography>First and last name can be managed from here.</Typography>
				</Box>}
				{isWpUser && input("Current password", "currentPassword", "password")}
				{isWpUser === false && input("Email", "email")}
				{input("First Name", "firstname")}
				{input("Last Name", "lastname")}
			</form>
			<Box className={classes.buttonContainer}>
				<Button disabled={hasErrors || isWpUser === undefined || updateUserResponse.isLoading} onClick={handleSubmit}>
					{updateUserResponse.isLoading ? <CircularProgress /> : "Save changes"}
				</Button>
				{onClose && <OutlineButton onClick={onClose}>Cancel changes</OutlineButton>}
			</Box>
		</Box>
	);
}

export default EditUserDetailsForm;