import {BreakpointsOptions, createTheme, Shadows, Theme} from "@mui/material";
import {CustomMuiTheme} from "@plumeuk/shapeshift-common/theming";
import {palette, templateConstants, toolBarHeight} from "../constants";
import {templateConstants as commonTemplateConstants, templateSpacing as commonTemplateSpacing, templateTransitions} from "@plumeuk/shapeshift-common/theming";
import {TypographyStyleOptions} from "@mui/material/styles/createTypography";
import {defaultThemeOptions} from "@plumeuk/shapeshift-common/theming"

const defaultOptions = defaultThemeOptions();

declare module "@mui/material/styles" {
	interface Theme extends CustomMuiTheme {}
	// allow configuration using `createTheme`
	interface ThemeOptions extends CustomMuiTheme{}
}

const breakpoints: BreakpointsOptions = {
	//Mobile  |		 x < sm
	//Tablet  |	sm < x < md
	//Desktop |		 x > md
	values: {
		// extra-small
		xs: 500,
		// small
		sm: 700,
		// medium
		md: 1200,
		// large
		lg: 1300,
		// extra-large
		xl: 1536
	}
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getFontStyles = (font: TypographyStyleOptions, removeProps: string[] = []) => {
	const styles: { [key: string]: any } = {
		fontWeight: !removeProps.includes("fontWeight") ? font.fontWeight : undefined,
		fontSize: !removeProps.includes("fontSize") ? font.fontSize : undefined,
		lineHeight: !removeProps.includes("lineHeight") ? font.lineHeight : undefined,
		color: !removeProps.includes("color") ? font.color : undefined,
		fontFamily: !removeProps.includes("fontFamily") ? font.fontFamily : undefined,
		textTransform: !removeProps.includes("textTransform") ? font.textTransform : undefined
	};

	if (breakpoints?.values?.sm && font[`@media (max-width: ${breakpoints.values.sm}px)`]) {
		styles[`@media (max-width: ${breakpoints.values.sm}px)`] = font[`@media (max-width: ${breakpoints.values.sm}px)`];
	}

	return styles;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getAllFontStyles = (theme: Theme, removeProps: string[] = []) => ({
	"& > p, li": getFontStyles(theme.typography.body1, removeProps),
	"& > h1": getFontStyles(theme.typography.h1, removeProps),
	"& > h2": getFontStyles(theme.typography.h2, removeProps),
	"& > h3": getFontStyles(theme.typography.h3, removeProps), //> training feed card title
	"& > h4": getFontStyles(theme.typography.h4, removeProps),
	"& > h5": getFontStyles(theme.typography.h5, removeProps),
	"& > h6": getFontStyles(theme.typography.h6, removeProps)
})

export const defaultTheme = createTheme({
	...defaultOptions,
	toolbarHeight: toolBarHeight,
	breakpoints,
	shadows: Array(25).fill("none") as Shadows,
	palette: {
		action: {
			hover: palette.purple
		},
		background: {
			default: palette.background
		},
		primary: {
			main: palette.purple
		},
		secondary: {
			main: palette.green
		},
		success: {
			main: palette.success
		},
		error: {
			main: palette.error
		},
		info: {
			main: palette.purple
		}
	},
	typography: {
		fontFamily: "Poppins, sans-serif",
		body1: {
			fontSize: "16px"
		},
		h1: {
			fontStyle: "normal",
			fontWeight: 700,
			fontSize: "50px",
			lineHeight: "120%"
		},
		h2: {
			fontStyle: "normal",
			fontWeight: 700,
			fontSize: "46px",
			lineHeight: "120%"
		},
		h3: {
			fontStyle: "normal",
			fontWeight: 500,
			fontSize: "22px",
			lineHeight: "30px"
		},
		h4: {
			fontStyle: "normal",
			fontWeight: 700,
			fontSize: "26px",
			lineHeight: "30px"
		},
		body2: {
			fontSize: "9pt"
		},
		h6: {
			fontSize: "20px"
		},
		button: {
			fontSize: "15px",
			textTransform: "none"
		},
		small: {
			fontSize: "12px",
			textTransform: "none"
		}
	},
	borderRadius: {
		small: templateConstants.borderRadius.small,
		medium: templateConstants.borderRadius.medium,
		large: templateConstants.borderRadius.large
	},
	borderWidth:{
		small: templateConstants.borderWidth.small,
		medium: templateConstants.borderWidth.medium,
		large: templateConstants.borderWidth.large
	},
	navBar: {
		statusprofile: {
			backgroundColor: "#CD00A4"
		}
	},
	components: {
		MuiPaper: {
			styleOverrides: {
				root: {
					background: palette.pastelBlue
				}
			}
		},
		MuiToolbar: {
			styleOverrides: {
				root: {
					boxShadow: "0px 3px 12px 0px #d1d1d195",
					height: toolBarHeight,
					backgroundColor: "#fff"
				}
			}
		},
		MuiDrawer: {
			styleOverrides: {
				root: {
					"& [ class*='userName']": {
						color: "black"
					},
					"& [ class*='outlineButton']": {
						color: "black",
						borderColor: "blue"
					}
				},
				paper: {
					backgroundColor: palette.pastelBlue + "! important"
				}
			}
		},
		MuiLinearProgress: {
			styleOverrides: {
				root: {
					borderRadius: "5px",
					height: "8px",
					backgroundColor: "#fff"
				},
				bar: {
					backgroundColor: palette.green,
					borderRadius: "10px"
				}
			}
		},
		MuiTab: {
			styleOverrides: {
				root: {
					fontSize: "16px",
					"&.Mui-selected": {
						color: "black"
					},
					color: "black"
				}
			}
		},
		MuiTabs: {
			styleOverrides: {
				root: {
					lineHeight: 0
				},
				indicator: {
					height: "4px",
					backgroundColor: palette.purple
				}
			}
		},
		MuiButton: {
			styleOverrides: {
				...defaultOptions.components?.MuiButton?.styleOverrides,
				root: {
					fontSize: "15px",
					backgroundColor: palette.darkBlue,
					color: "white",
					padding: "18px 30px",
					borderRadius: "15px",
					transition: "opacity .3s ease-in-out",
					"&:hover": {
						backgroundColor: palette.darkBlue,
						opacity: 0.8
					},
					":disabled": {
						opacity: 0.6,
						color: "white"
					}
				},
				text: {
					fontSize: "15px",
					backgroundColor: palette.darkBlue,
					color: "white",
					padding: "18px 30px",
					borderRadius: "15px",
					transition: "opacity .3s ease-in-out",
					"&:hover": {
						backgroundColor: palette.darkBlue,
						opacity: 0.8
					},
					":disabled": {
						opacity: 0.6,
						color: "white"
					}
				},
				outlined: {
					backgroundColor: "transparent",
					color: palette.green,
					borderWidth: templateConstants.borderWidth.small,
					borderColor: palette.green,
					"&:hover": {
						color: palette.green,
						backgroundColor: "transparent",
						borderColor: palette.green
					},
					"&:disabled": {
						color: palette.green
					}
				}
			},
			variants: [
				...(defaultOptions.components?.MuiButton?.variants ?? []),
				{
					props: {
						//Custom MuiButton variant for blank buttons
						variant: "link"
					},
					style: {
						border: 0,
						padding: 0,
						backgroundColor: "transparent",
						color: palette.green,
						"& svg path": {
							stroke: palette.green,
							fontSize: commonTemplateConstants.iconSizes.large
						},
						"&:hover": {
							backgroundColor: "transparent"
						},
						"& [class$='startIcon']": {
							marginRight: `${commonTemplateSpacing.spacer * 0.85}rem`
						}
					}
				}
			]
		},
		MuiSelect: {
			styleOverrides: {
				standard: {
					"&:focus": {
						backgroundColor: "transparent"
					}
				}
			}
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					backgroundColor: "#fff",
					border: "none",
					padding: "5px 10px",
					borderRadius: "10px"
				}
			}
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					borderRadius: 100,
					backgroundColor: palette.darkBlue,
					color: "#fff"
				}
			}
		},
		MuiTable: {
			styleOverrides: {
				root: {
					overflow: "auto"
				}
			}
		},
		MuiTableRow: {
			styleOverrides: {
				root: {
					"&.MuiTableRow-hover:hover": {
						backgroundColor: palette.pastelBlue
					},
					"&:hover": {
						backgroundColor: palette.pastelBlue
					}
				}
			}
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					fontSize: "14px",
					lineHeight: "1.35",
					padding: "12px 15px"
				}
			}
		}
	}
});
