import {Box} from "@mui/material";
import {FC, useContext, useEffect} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {AuthContext} from "@plumeuk/shapeshift-identity";
import {ResetPasswordForm} from "@plumeuk/shapeshift-common/reset-password";
import {makeStyles} from "tss-react/mui";

const logo = "/logo.svg"

const useStyles = makeStyles()((theme) => ({
	loginPage:{
		backgroundColor: theme.palette.background.default,
		minHeight: "100%",
		width: "100%",
		boxSizing: "border-box",
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		[theme.breakpoints.down("sm")]: {
			backgroundColor: theme.palette.background.paper
		}
	},
	container: {
		marginTop: "15px",
		margin: "auto",
		display: "flex",
		flexDirection: "column",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column-reverse"
		}
	},
	imgContainer: {
		margin: "40px 0",
		textAlign: "center",
		width: "100%",
		display: "inline-flex",
		maxHeight: "100px",
		[theme.breakpoints.down("sm")]: {
			margin: "30px 0"
		},
		"& img": {
			[theme.breakpoints.down("sm")]: {
				width: "149px"
			}
		}
	},
	loginForm: {
		marginTop: "15px"
	}
}));

export const ResetPasswordPage: FC = () => {
	const {classes} = useStyles();
	const {authenticated} = useContext(AuthContext);
	const navigate = useNavigate();

	const query = new URLSearchParams(useLocation().search)

	const code = query.get("code") ?? ""

	useEffect(() => {
		if(authenticated) navigate("/courses")
	}, [authenticated])

	return (
		<Box className={classes.loginPage}>
			<Box className={classes.container}>
				<ResetPasswordForm
					className={classes.loginForm}
					title="Confirm your new password"
					onLoginLinkClick={() => navigate("/")}
					code={code ?? ""}
				/>
				<div className={classes.imgContainer}>
					<img src={logo} />
				</div>
			</Box>
		</Box>
	);
}