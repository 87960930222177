import {ValidationDoc} from "@plumeuk/use-validate";
import {IEditUserRequest} from "../../types/IUser";

export const editUserRequestValidationDoc: ValidationDoc<IEditUserRequest> = {
	firstname: [
		"required"
	],
	lastname: [
		"required"
	],
	currentPassword: [
		{
			errorMessage: "",
			validate: (e) => {
				return e.isWpUser === false || !!e.currentPassword
			}
		}
	]
}

